.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%; /*30 von links und 58 von rechts*/
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-background-variant);
    border: 1px solid transparent;
    border-radius: 1.2rem;
    padding: 1.2rem;
    text-align: center;
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    transform: rotate(20deg);
}

.contact__image-icon {
    font-size: 1.5rem;
    margin: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/* ================================ FORM ================================ */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

form.button {
    transform: rotate(20deg);
}

input, textarea {
    width: 100%;
    background: transparent;
    color: var(--color-white);
    border: 2px solid var(--color-primary-variant);
    border-radius: 0.5rem;
    padding: 1.5rem;
    resize: none; /*Man kann die Textarea nicht mit der Maus kleiner oder größer machen.*/
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .contact__container {
        grid-template-columns: 1fr; /*Damit der contact-options und die Input-Felder untereinander und nicht nebeneinander stehen.*/
        gap: 2rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .contact__container {
        width: var(--container-width-small);
    }
}