@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-background: #1f1f38;
    --color-background-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-large: 75%;
    --container-width-medium: 86%;
    --container-width-small: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-background);
    color: var(--color-white);
    line-height: 1.7;
    
}

/* =============== GENERAL STYLES =============== */

.container {
    width: var(--container-width-large);
    margin: 0 auto; /*All containers are horizontally*/
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section { /*Each component is in a container*/
    margin-top: 8rem;
}

section > h2, section > h5 { /*Each section has at least h1 and h2*/
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover { /*If someone gets over an a-tag-component, then do this:*/
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem; /*0.75rem nach oben und 1.2rem nach links und rechts*/
    border-radius: 0.4rem;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-background);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-background);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-medium); /*Alle Container haben diese Breite auf mobilen Geraeten*/
    }

    section {
        margin-top: 6rem;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES/Mobile phones) =============== */

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-small); /*Alle Container haben diese Breite auf mobilen Geraeten*/
    }

    section > h2 {
        margin-bottom: 2rem;
    }
}