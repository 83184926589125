nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content; /*Der Background ist so breit wie die Nav-Komponente, mehr nicht*/
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed; /*Auch beim scrollen bleibt die Navbar an einem Ort*/
    left: 50%; /*Weil die Navbar nicht ganz mittig sitzt...*/
    transform: translateX(-50%); /*... macht man dies*/
    bottom: 2rem; /*Dann bleibt die Navbar immer am unteren Rand*/
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem; /*Dadurch wird die Navbar größer*/
    border-radius: 50%; /*Wird erst ...*/
    display: flex; /*... dadurch sichtbar*/
    color: var(--color-light);font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active { /*Klasse, die beim ersten Aufruf aktiviert ist*/
    background: var(--color-primary);
    color: var(--color-background);
}