header {
    height: 60vh;
    padding-top: 4rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ==================== CTA ====================*/

.cta {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem; /*Place between the two buttons*/
    justify-content: center;
}

/* ==================== HEADER SOCIALS ====================*/

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    position: absolute;
    left: 7rem;
    bottom: 2rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ==================== ME ====================*/

.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 16.5rem;
    position: absolute;
    left: calc(50% - 11rem); /*Von links aus: Das Bild ist in der Mitte (22/2 = 11)*/
    margin-top: 4rem;
    border-radius: 6rem 6rem 6rem 6rem; /*top-left top-right bottom-right bottom-left*/
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem; /*From top right bottom left*/
}

/* ==================== SCROLL DOWN ====================*/

.scroll__down {
    position: absolute;
    right: 4rem;
    bottom: 6.5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials, .scroll__down {
        display: none;
    }
}