.container.values__container {
    width: 60%;
    padding-bottom: 4rem;
}

.value__image {
    width: 6rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.value__image img {
    width: 150%;
}

.value {
    background: var(--color-background-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.value__description {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0; /*top, left/right and bottom*/
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .conatainer.values__container {
        width: 60%;
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .conatainer.values__container {
        width: var(--container-width-small);
    }

    .value__description {
        width: var(--container-width-small);
    }

    .container.values__container {
        width: 60%;
        padding-bottom: 0rem;
    }
}