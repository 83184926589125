.about__container {
    display: grid;
    grid-template-columns: 35% 50%; /*35% vom linken Rand und 50% vom rechten Rand entfernt. Die Spalten sind die divs.*/
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1; /*Die Höhe ist immer genauso groß/hoch wie die Breite (1:1)*/
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
    display: grid;
    place-items: center; /*Das grid hat nur ein Element, nämlich das Bild. Dies ist genau in der Mitte.*/

}

.about__me-image {
    border-radius: 2rem; /*Für den Hintergrund.*/
    overflow: hidden; /*Für das vordere Bild.*/
    transform: rotate(10deg);
    transition: var(--transition); /*Die Rotation ist/wird langsamer.*/
}

.about__me-image:hover {
    transform: rotate(0deg);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 3fr); /*3 Spalten*/
    gap: 1.5rem;
}

.about__card {
    background: var(--color-background-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem; /*Abstand zwischen Inhalt und äußester Rand (border)*/
    text-align: center;
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    transition: var(--transition); /*Smoother hover-Effekt*/
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem; /*2 für top, 0 für links und rechts und 2.6 für bottom*/
    color: var(--color-light);
}

li {
    margin: 1rem;
}

/* =============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem; /*2 vom top, auto für links und rechts und 4 für bottom*/
    }

    .about__content p {
        margin: 1rem 0 1.5; /*1 vom top, 0 für links und rechts und 4 vom bottom*/
    }
}

/* =============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__content p {
        margin: 1.5rem 0; /*1.5 vom top und der Rest ist 0.*/
    }
}